<script setup lang="ts">
import { ref, onMounted } from "vue";
import IconPatch from "@/components/app/IconPatch.vue";
import {useRouter} from "vue-router";

const props = defineProps({
  event : {
    required: true,
    type: Object
  }
});
const eventData = ref();
const router = useRouter();

onMounted(() => {
  const { icon, color } = getIconAndColor(props.event.type || props.event.status);
  const description = props.event.description ? props.event.description : 'Status: ' + props.event.status_name;
  const eventName = props.event.name || props.event.title || `${props.event.first_name} ${props.event.last_name}` || 'Unknown Event';
  const formattedDate = formatDate(props.event?.created_at || props.event.date);
  const deeplink = ref();

  if (props.event.actionUrl && props.event.actionUrl !== '/') {
    const url = new URL(props.event.actionUrl);
    deeplink.value = url.pathname + url.search;
  }
  else if (!props.event.actionUrl && props.event.eventable && (props.event.type === 'recommendation' || props.event.type === 'promotor')) {
    deeplink.value = createActionLink(props.event.type, props.event.eventable.id);
  }
  else if (!props.event.actionUrl && props.event.status) {
    deeplink.value = createActionLink('recommendation', props.event.id);
  }

  eventData.value = {
    ...props.event,
    icon,
    color,
    isToday: formattedDate.startsWith('Heute'),
    formattedDate,
    description,
    eventName,
    deeplink
  };
});

const getIconAndColor = (typeOrStatus) => {
  switch (typeOrStatus) {
    case 'click':
      return { icon: 'cursor-default', color: '#EFD500' };
    case 'promotor':
      return { icon: 'account', color: '#009EE3' };
    case 'recommendation':
    case 'info':
      return { icon: 'thumb-up', color: '#FF6A00' };
    case 'commission':
      return { icon: 'currency-eur', color: '#009EE3' };
    case 'reward':
      return { icon: 'party-popper', color: '#27AE60' };
    case 'interested':
      return { icon: 'thumb-up', color: '#FF6A00' };
    case 'not_interested':
      return { icon: 'thumb-down', color: '#ff1500' };
    case 'customer':
      return { icon: 'account', color: '#27AE60' };
    case 'link_send':
      return { icon: 'email-outline', color: '#009EE3' };
    default:
      return { icon: 'bell', color: '#009EE3' };
  }
};
const formatDate = (timestamp) => {
  const activityDate = new Date(timestamp);
  const today = new Date();
  const hours = activityDate.getHours().toString().padStart(2, '0');
  const minutes = activityDate.getMinutes().toString().padStart(2, '0');

  // check if today
  if (
      activityDate.getDate() === today.getDate() &&
      activityDate.getMonth() === today.getMonth() &&
      activityDate.getFullYear() === today.getFullYear()
  ) {
    // if "Heute hh:mm"
    return `Heute ${hours}:${minutes}`;
  } else {
    // else "dd.mm.yyyy hh:mm"
    const day = activityDate.getDate().toString().padStart(2, '0');
    const month = (activityDate.getMonth() + 1).toString().padStart(2, '0');
    const year = activityDate.getFullYear();
    return `${day}.${month}.${year} ${hours}:${minutes}`;
  }
};
const createActionLink = (type, id) => {
  if (!(type || id)) return

  return `/${type}?${type}=${id}`;
};
</script>

<template>
  <div
      v-if="eventData"
      @click="router.push(eventData.deeplink);"
      class="d-flex mb-3 pa-2 pl-3 activity-feed"
      :class="{ 'today-activity': eventData.isToday, 'clickable-activity': eventData.deeplink }"
  >
    <icon-patch class="mr-2" :icon="eventData.icon" :color="eventData.color"></icon-patch>
    <div class="ml-3 activity-texts">
      <p class="font-weight-bold text-xs text-black" style="font-size: x-small;">{{ eventData.eventName }}</p>
      <p style="font-size: xx-small;">{{ eventData.description }}</p>
      <p style="font-size: 0.5rem;">{{eventData.status ? 'Empfehlung eingegangen am ' : ''}} {{ eventData.formattedDate }}</p>
    </div>
    <div class="iconsPanel">
      <v-chip v-if="eventData.isToday" color="green" variant="flat" size="x-small" class="new-badge justify-center">NEU</v-chip>
      <v-icon v-if="eventData.deeplink" icon="mdi-eye-arrow-right-outline" color="grey lighter-1" size="small" class="eye-icon"></v-icon>
    </div>
  </div>
</template>

<style scoped lang="scss">
.activity-feed {
  position: relative;
  width: 100%;
  background: white;
  margin: auto 0;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  &.today-activity {
    border: 1.5px solid #4CAF50;
  }
}

.clickable-activity {
  cursor: pointer;
  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.10);
    transition: all 0.3s ease;
  }
}

.activity-texts {
  width: 100%;
  display: flex;
  flex-direction: column;
  p {
    margin-block-end: 0 !important;
  }
}

.iconsPanel {
  .new-badge {
    position: absolute;
    min-width: 33px;
    border-radius: 8px !important;
    top: -9px;
    right: -9px;
  }
  .eye-icon {
    position: absolute;
    bottom: 5px;
    right: 6px;
  }
}
</style>
