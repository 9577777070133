import axios from "axios";
import {useAuthStore} from "@/stores/auth";


export async function handleXSRFError(error) {
    try {
        // Get original request
        const originalRequest = error.config

        // If we already had a retry, fail
        if (originalRequest?._retry === true) return Promise.reject(error)

        // Try to refresh XSRF token when this is the cause
        if (error.response?.status === 419) {
            originalRequest._retry = true
            console.log("Got CSRF error, retrying since we now should have got the new header")
            return axios(originalRequest)
        }

        // Continue chain
        return Promise.reject(error)

    } catch (e) {
        console.error("Error while intercepting xsrf")
        console.error(e)
        return Promise.reject(error)
    }
}

export async function handleAuthError(error){
    const auth = useAuthStore()
    try {
        // Get original request
        const originalRequest = error.config

        // If it is an auth request, we should not auth intercept that
        if (originalRequest.url === "/api/auth/refresh") return Promise.reject(error)
        if (originalRequest.url === "/api/auth/login") return Promise.reject(error)

        // Sometimes in parallel the auth token was refreshed, retry updating the auth tokens
        // Since axios requests seems to be worked off in sequence, we do not need a while loop here to wait for it...
        // I worked on this for HOURS and it seems like concurring requests between tabs are not a thing...
        if (originalRequest?._retry !== true) {
            originalRequest.headers['Authorization'] = `Bearer ${auth.accessToken}`
            originalRequest._retry = true
            return axios(originalRequest)
        }

        // Check if this is our request and if it is 401 error
        if (originalRequest?.baseURL === axios.defaults.baseURL && error.response.status === 401) {
            console.log("Request failed going back to login and let request fail")
            // If it fail, go back to login page
            // => We need to reload the page here, so we have a fresh state, currently sometimes the states if logged etc. are messing up
            const auth = useAuthStore()
            await auth.reset()
            window.location.href = "/login"
            return Promise.reject(error)
        }

        // Continue error chain
        return Promise.reject(error)

    } catch (e) {
        console.error("Error while intercepting auth")
        console.error(e)
        return Promise.reject(error)
    }
}