

export function getBoolFromStorage(name) {
    return localStorage.getItem(name) !== null
}

export function setBoolToStorage(name, value) {
    if (value === true) {
        localStorage.setItem(name, 1)
        return
    }
    localStorage.removeItem(name)
}

export function setStringToStorage(name, value) {
    localStorage.setItem(name, value ?? '')
}

export function getStringFromStorage(name) {
    const value = localStorage.getItem(name)
    return value === '' ? null : value
}

export function setValueToStorage(name, value) {
    localStorage.setItem(name, value)
}

export function getValueFromStorage(name) {
    return localStorage.getItem(name)
}