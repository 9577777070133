<script>

import { useUserStore } from "@/stores/user";
import { useAuthStore } from "@/stores/auth";

export default {
  setup() {
    const router = useRouter();
    const userData = JSON.parse(localStorage.getItem("userData") || "null");
    const authStore = useAuthStore();
    const userStore = useUserStore();
    const avatarBadgeProps = {
      dot: true,
      location: "bottom right",
      offsetX: 3,
      offsetY: 3,
      color: "success",
      bordered: true,
    };
    return {
      router,
      userData,
      authStore,
      userStore,
      avatarBadgeProps,
    };
  },
  methods: {
    logout: async function () {

      await this.authStore.logout()

      await this.router.replace('/login')
    },
    showFAQs() {
      window.open("https://www.tellscale.de/", "_blank").focus();
    },
  },
};
</script>

<template>
  <VBadge v-bind="avatarBadgeProps">
    <VAvatar class="cursor-pointer" variant="tonal">
      <VImg v-if="userStore?.user?.avatar_path" :src="userStore.user?.avatar_path" />
      <VIcon v-else icon="mdi-account-outline" />
    </VAvatar>

      <!-- SECTION Menu -->
      <VMenu activator="parent" width="230" location="bottom end" offset="14px">
        <VList>
          <!-- 👉 User Avatar & Name -->
          <VListItem>
            <template #prepend>
              <VListItemAction start>
                <VBadge v-bind="avatarBadgeProps">
                  <VAvatar size="40" variant="tonal">
                    <VImg v-if="userStore?.user?.avatar_path" :src="userStore.user?.avatar_path" />
                    <VIcon v-else icon="mdi-account-outline" />
                  </VAvatar>
                </VBadge>
              </VListItemAction>
            </template>

            <VListItemTitle class="font-weight-semibold">
              {{ userData.first_name }} {{ userData.last_name }}
            </VListItemTitle>
            <VListItemSubtitle class="text-disabled">
              {{ userData.role }}
            </VListItemSubtitle>
          </VListItem>

          <VDivider class="my-2" />

          <!-- 👉 Profile -->
          <VListItem :to="{ name: 'setting-account' }">
            <template #prepend>
              <VIcon class="me-2" icon="mdi-account-outline" size="22" />
            </template>

            <VListItemTitle>Profil</VListItemTitle>
          </VListItem>

          <!-- 👉 Pricing -->
          <VListItem :to="{ name: 'setting-payment' }">
            <template #prepend>
              <VIcon class="me-2" icon="mdi-currency-usd" size="22" />
            </template>

            <VListItemTitle>Zahlungsdaten</VListItemTitle>
          </VListItem>

          <!-- 👉 FAQ -->
          <VListItem @click="showFAQs()">
            <template #prepend>
              <VIcon class="me-2" icon="mdi-help-circle-outline" size="22" />
            </template>

            <VListItemTitle>Infos</VListItemTitle>
          </VListItem>

          <!-- Divider -->
          <VDivider class="my-2" />

          <!-- 👉 Logout -->
          <VListItem @click="logout()">
            <template #prepend>
              <VIcon class="me-2" icon="mdi-logout-variant" size="22" />
            </template>

            <VListItemTitle>Logout</VListItemTitle>
          </VListItem>
        </VList>
      </VMenu>
      <!-- !SECTION -->
  </VBadge>
</template>
