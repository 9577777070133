import { createVuetify } from 'vuetify'
import defaults from './defaults'
import { icons } from './icons'
import theme from './theme'

// Styles
import '@core/scss/libs/vuetify/index.scss'
import 'vuetify/lib/styles/main.sass'
import 'vuetify/styles'
import {VFab} from "vuetify/labs/VFab";
import {de} from 'vuetify/locale';

export default createVuetify({
  defaults,
  icons,
  theme,
  locale: {
    messages: { de },
    locale: 'de',
  },
  components: {
    VFab
  }
})
