<script setup>
import { useDashboardOverviewStore } from "@/views/apps/dashbaord/dashboardOverviewStore";
import ActivityNotificationWidget from "@/pages/dashboard/components/ActivityNotificationWidget.vue";

const activityStatistics = ref([]);
const dashboardOverviewStore = useDashboardOverviewStore();
const isDataLoading = ref(true);

const getDashboardOverviewData = () => {
  dashboardOverviewStore
    .fetchDashboardNotificationInfo()
    .then((response) => {
      isDataLoading.value = false;
      activityStatistics.value = Object.entries(response?.data)
        .flatMap(([date, item]) =>
            (item.title === "Promotor erstellt" || item.title === "Neue Empfehlung") ? [{ ...item, date }] : []
        );
    })
    .catch((error) => {
      isDataLoading.value = false;
      console.log(error);
    });
};

// 👉 Fetch Notification Data
watchEffect(() => {
  getDashboardOverviewData();
});
</script>

<template>
  <div class="d-flex justify-end">
    <div class="arrow-up"></div>
  </div>
  <div class="activity-panel-background">
    <div v-if="isDataLoading" class="d-flex justify-center align-center h-100">
      <v-progress-circular indeterminate/>
    </div>
    <div v-else class="activity-panel-notifications">
      <activity-notification-widget v-for="activityStatistic in activityStatistics" :event="activityStatistic"></activity-notification-widget>
    </div>
  </div>
</template>

<style scoped lang="scss">
.arrow-up {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  margin-top: -10px;
  margin-bottom: -0.5px;
  border-bottom: 19px solid white;
  z-index: 10;
}

.activity-panel-background {
  width: 100%;
  max-height: 360px;
  margin-left: 12px;
  padding: 10px;
  border-radius: 16px;
  background: rgb(255,255,255);
  background: -moz-linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 34%, rgba(247,252,255,1) 70%, rgba(239,250,255,1) 87%, rgba(230,247,255,1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 34%, rgba(247,252,255,1) 70%, rgba(239,250,255,1) 87%, rgba(230,247,255,1) 100%);
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 34%, rgba(247,252,255,1) 70%, rgba(239,250,255,1) 87%, rgba(230,247,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#e6f7ff",GradientType=1);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: all .3s ease-in-out;
}

.activity-panel-notifications {
  max-height: 340px;
  padding: 10px;
  border-radius: 12px;
  overflow-y: auto;
}
</style>
