<script setup>
import { useLayouts } from "@layouts";
import {
  VerticalNavGroup,
  VerticalNavLink,
  VerticalNavSectionTitle,
} from "@layouts/components";
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import {useUserStore} from "@/stores/user";
import {useAppStore} from "@/stores/app";
import { config } from '../config'

const props = defineProps({
  tag: {
    type: [String, null],
    required: false,
    default: "aside",
  },
  navItems: {
    type: null,
    required: true,
  },
  isOverlayNavActive: {
    type: Boolean,
    required: true,
  },
  toggleIsOverlayNavActive: {
    type: Function,
    required: true,
  },
});

const user = useUserStore()
const app = useAppStore()
const refNav = ref();
const currentUrl = window.location.href.includes('staging');
const { width: windowWidth } = useWindowSize();
const {
  isVerticalNavCollapsed: isCollapsed,
  isLessThanOverlayNavBreakpoint,
  isVerticalNavMini,
  isAppRtl,
} = useLayouts();
const hideTitleAndIcon = isVerticalNavMini(windowWidth, !isCollapsed);
const resolveNavItemComponent = (item) => {
  if ("heading" in item) return VerticalNavSectionTitle;
  if ("children" in item) return VerticalNavGroup;

  return VerticalNavLink;
};

const route = useRoute();
watch(
  () => route.name,
  () => {
    props.toggleIsOverlayNavActive(false);
  }
);
const isVerticalNavScrolled = ref(false);
const updateIsVerticalNavScrolled = (val) =>
  (isVerticalNavScrolled.value = val);

// Perfect scrollbar
const perfectScrollbarSettings = {
  maxScrollbarLength: 60,
  wheelPropagation: false,
};
const handleNavScroll = (evt) => {
  isVerticalNavScrolled.value = evt.target.scrollTop > 0;
};
const secoundArr = [
  {
    icon: { icon: "uil:setting" },
    title: "Einstellungen",
    to: "setting",
  },
];
const chatClick = () => {
  app.toggleIntercom()
  isCollapsed.value = false
}
</script>

<template>
  <Component :is="props.tag" ref="refNav" class="layout-vertical-nav" :class="[
    {
      'overlay-nav': isLessThanOverlayNavBreakpoint(windowWidth),
      'visible': isOverlayNavActive,
      'scrolled': isVerticalNavScrolled,
    },
  ]">
    <!-- 👉 Header -->
    <div class="first-portion">
      <div class="nav-header logo-container">
        <slot name="nav-header">
          <div class="nav-header-burger-menu">
            <!-- 👉 Vertical nav actions -->
            <template v-if="!isLessThanOverlayNavBreakpoint(windowWidth)">
              <v-slide-x-reverse-transition mode="out-in">
                <v-icon
                    :is="config.app.iconRenderer || 'div'"
                    class="header-action"
                    v-bind="config.icons.verticalNavPinned"
                    :key="`icon-${isCollapsed}`"
                    :icon="isCollapsed ? 'mdi-arrow-expand-right' : 'mdi-arrow-expand-left'"
                    @click="isCollapsed = !isCollapsed"
                    style="color: #9fa2b4"
                ></v-icon>
              </v-slide-x-reverse-transition>
            </template>
          </div>
          <div v-bind:style="[ currentUrl ? {filter: 'hue-rotate(155deg)'} : {} ]" class="nav-header-logo">
            <RouterLink to="/" class="app-logo d-flex align-center gap-x-3 app-title-wrapper">
              <Transition v-if="!hideTitleAndIcon && !isLessThanOverlayNavBreakpoint(windowWidth)" name="vertical-nav-app-title">
                <img height="60" src="../../assets/images/logos/appLogo.png" alt="" />
              </Transition>
              <Transition v-else name="vertical-nav-app-title">
                <img width="32" src="../../assets/images/logos/appFavicon.png" alt="" />
              </Transition>
            </RouterLink>
          </div>
        </slot>
      </div>
      <slot name="before-nav-items">
        <div class="vertical-nav-items-shadow" />
      </slot>
      <div class="menu-list-item-container">
        <slot name="nav-items" :update-is-vertical-nav-scrolled="updateIsVerticalNavScrolled">
          <PerfectScrollbar :key="isAppRtl" tag="ul" class="nav-items" :options="perfectScrollbarSettings" @ps-scroll-y="handleNavScroll">
              <Component :is="resolveNavItemComponent(item)" v-for="(item, index) in navItems" :key="index" :item="item"/>
          </PerfectScrollbar>
        </slot>
      </div>
    </div>
    <div class="second-section-container">
      <v-card v-if="!hideTitleAndIcon && !isLessThanOverlayNavBreakpoint(windowWidth)" class="ma-2 pa-2 d-flex" flat rounded="lg" style="background-color: #e6f7ff !important; cursor: pointer; max-height: 68px !important;" @click="chatClick">
        <img class="mt-1" height="40" src="/appFavicon.png" alt="" />
        <div class="align-end ml-7">
          <h3>Hast du Fragen?</h3>
          <p class="ma-0 mr-2 d-inline-block">Live-Chat öffnen</p>
          <v-icon icon="mdi:mdi-open-in-new" color="black" size="small"></v-icon>
        </div>
      </v-card>
      <div v-else class="d-flex cursor-pointer" @click="chatClick">
        <v-icon icon="mdi-face-agent" class="ma-auto mb-4"></v-icon>
      </div>
      <v-divider></v-divider>
<!--      intercom-->
      <slot class="secound-section-item-container" name="nav-items"
        :update-is-vertical-nav-scrolled="updateIsVerticalNavScrolled">
        <PerfectScrollbar :key="isAppRtl" tag="ul" class="nav-items mt-2" :options="perfectScrollbarSettings"
          @ps-scroll-y="handleNavScroll">
          <Component :is="resolveNavItemComponent(item)" v-for="(item, index) in secoundArr" :key="index" :item="item" />
        </PerfectScrollbar>
      </slot>
    </div>
  </Component>
</template>

<style lang="scss">
@use "@configured-variables" as variables;
@use "@layouts/styles/mixins";

// 👉 Vertical Nav
.layout-vertical-nav {
  position: fixed;
  z-index: variables.$layout-vertical-nav-z-index;
  display: flex;
  flex-direction: column;
  block-size: 100%;
  inline-size: variables.$layout-vertical-nav-width;
  inset-block-start: 0;
  inset-inline-start: 0;
  transition: transform 0.25s ease-in-out, inline-size 0.25s ease-in-out,
    box-shadow 0.25s ease-in-out;
  will-change: transform, inline-size;

  .nav-header {
    //display: flex;
    //align-content: center;
    .header-action {
      cursor: pointer;
    }
  }

  .app-title-wrapper {
    margin-inline-end: auto;
  }

  .nav-items {
    block-size: 100%;

    // ℹ️ We no loner needs this overflow styles as perfect scrollbar applies it
    // overflow-x: hidden;

    // // ℹ️ We used `overflow-y` instead of `overflow` to mitigate overflow x. Revert back if any issue found.
    // overflow-y: auto;
  }

  .nav-item-title {
    overflow: hidden;
    margin-inline-end: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  // 👉 Collapsed
  .layout-vertical-nav-collapsed & {
    &:not(.hovered) {
      inline-size: variables.$layout-vertical-nav-collapsed-width;
    }
    .nav-header-logo {
      margin-top: 4rem;
    }
  }

  // 👉 Overlay nav
  &.overlay-nav {
    inline-size: variables.$layout-vertical-nav-collapsed-width;
  }
}

.menu-list-item-container {
  margin-top: 2.375rem !important;
}

.seprator {
  border: none !important;
  border-bottom: 1px solid #d3d3d3 !important;
  margin-left: 0 !important;
  margin-bottom: 10px;
}

.logo-container {
  height: 150px;
}

.nav-header-burger-menu {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
}

.nav-header-logo {
  display: flex;
  margin-top: 3rem;
}

.router-link-active {
  background: #e7e7ed !important;
  color: rgb(var(--v-theme-primary)) !important;
  box-shadow: none !important;
}

.layout-nav-type-vertical .layout-vertical-nav .nav-link > :first-child {
  block-size: 3.5rem !important;
  margin-inline: auto !important;
  padding-inline: 1.1rem 1rem !important;
  margin: 1% 5% !important;
  white-space: nowrap !important;
  border-end-start-radius: 1rem !important;
  border-end-end-radius: 1rem !important;
  border-start-start-radius: 1rem !important;
  border-start-end-radius: 1rem !important;
}

.nav-link:hover {
  border-end-start-radius: 1rem !important;
  border-end-end-radius: 1rem !important;
  border-start-start-radius: 1rem !important;
  border-start-end-radius: 1rem !important;
}
</style>
