import { object, number, union, literal } from "zod";
import { Model } from "@/stores/base-model";

export class Price extends Model {
    schema = schema;

    /**
     * List to map the intervals
     *
     */
    private intervalMap: [typeof this.values.interval, string][] = [
        ['Month', 'monatlich'],
        ['Year', 'jährlich'],
    ];

    /**
     * List to map the intervals
     *
     */
    private intervalMapExtended: [typeof this.values.interval, string][] = [
        ['Month', 'Monats'],
        ['Year', 'Jahres'],
    ];

    /**
     * Get the interval in the correct format
     */
    public get intervalFormatted(): string {
        const entry = this.intervalMap.find(entry => entry[0] == this.values.interval);
        return entry ? entry[1] : this.values.interval;
    };

    /**
     * Get the interval in the correct format
     */
    public get intervalExtended(): string {
        const entry = this.intervalMapExtended.find(entry => entry[0] == this.values.interval);
        return entry ? entry[1] : this.values.interval;
    };

    public baseAmountFormatted(withCurrency = false): string {
        if(this.values.baseAmount === 0) return withCurrency ? `0 ${this.currencySymbol}` : '0';
        const amountFormatted = this.values.baseAmount.toFixed(2).toString().replace('.', ',');
        return withCurrency ? `${amountFormatted} ${this.currencySymbol}` : amountFormatted;
    }

    /**
     * List to map the currencies
     *
     */
    private currencyMap: [typeof this.values.currency, string][] = [
        ['EUR', '€'],
        ['USD', '$'],
    ];

    /**
     * List to map the currencies in extended version
     *
     */
    private currencyMapExtended: [typeof this.values.currency, string][] = [
        ['EUR', 'Euro'],
        ['USD', 'Dollar'],
    ];

    /**
     * Get the symbol for the given currency
     */
    public get currencySymbol(): string {
        const entry = this.currencyMap.find(entry => entry[0] == this.values.currency);
        return entry ? entry[1] : this.values.currency;
    };

    /**
     * Get the formatted version for the given currency
     */
    public get currencyFormatted(): string {
        const entry = this.currencyMapExtended.find(entry => entry[0] == this.values.currency);
        return entry ? entry[1] : this.values.currency;
    };

    /**
     * Check if the subscription is seat based
     */
    public get isSeatBased(): boolean {
        return this.values.seatAmount != null;
    }
}

export const schema = object({
    id: number(),
    interval: union([
        literal('Month'),
        literal('Year'),
    ]),
    currency: union([
        literal('EUR'),
        literal('USD'),
    ]),
    baseAmount: number(),
    seatAmount: number().nullable(),
})
