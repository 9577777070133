import axios from "axios";
import { defineStore } from "pinia";
export const useDashboardOverviewStore = defineStore("DashboardOverviewStore", {
    actions: {
        // Fetch all Dashboard Overview Information
        fetchDashboardOverviewInfo() {
            return axios.get(`/api/dashboard`);
        },
        fetchDashboardNotificationInfo() {
            return axios.get(`/api/user/notifications`);
        },
        sendEmailVerification(data) {
            return axios.post(`/api/resend-verification-mail`, data);
        },
        // Get App Version
        getAppVersion() {
            return axios.get(`/api/release-note`);
        },
        sendBugReport(data) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/api/user/contact`, data)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
    },
});
