import { object, string, number, array, coerce } from "zod";
import { Model } from "@/stores/base-model";
import { Price, schema as priceSchema } from "@/stores/subscription/price-model";

export class Subscription extends Model {
    public schema = schema

    public get name(): string {
        return this.values.display_name ?? this.values.name;
    };

    /**
     * Check if the subscription is selected
     */
    public isActive: boolean = false;

    /**
     * The price used if the subscription is active
     */
    public priceUsed: Price | null = null;

    /**
     * The price used if the subscription is active
     */
    public pricePreview: Price | null = null;

    /**
     * The raw data of stripe subscription
     */
    public stripe: StripeSubscription | null = null;

    /**
     * The features this subscription does not include
     */
    public missingFeatures: Feature[] = []

    public resolveAllFeatures(): Feature[] {
        if(!this.values.inherited_plan) return this.values.features;

        let features = this.values.features.concat(this.values.inherited_plan.features);

        const names = [...new Set(features.map(feature => feature.name))];

        return names.map(name => features.find(feature => feature.name === name)) as Feature[];
    }
}

export type StripeSubscription = {
    ends_at: string,
    trial_ends_at: string,
}

export type Feature = {
    name: string,
    display_name: string
    resolved_description: string
    visible: boolean
}

const featureSchema = object({
    name: string(),
    display_name: string(),
    resolved_description: string(),
    visible: coerce.boolean(),
})

const inheritedPlanSchema = object({
    id: number(),
    name: string(),
    display_name: string().nullable(),
    features: array(featureSchema),
});

const schema = object({
    id: number(),
    name: string(),
    price_monthly: priceSchema.transform(($price) => $price ? Price.validateAndCreate($price) : null).nullable(),
    price_yearly: priceSchema.transform(($price) => $price ? Price.validateAndCreate($price) : null).nullable(),
    price_admin_coverage: priceSchema.transform(($price) => $price ? Price.validateAndCreate($price) : null).nullable(),
    default: coerce.boolean(),
    visible: coerce.boolean(),
    trial_days: number().optional(),
    display_name: string().nullable(),
    inherited_plan: inheritedPlanSchema.nullable(),
    use_for_team_membership: coerce.boolean(),
    color: string(),
    description: string().nullable(),
    features: array(featureSchema),
    all_features: array(featureSchema)
});

