import { Icon } from '@iconify/vue'
import { aliases } from 'vuetify/lib/iconsets/mdi'
import { mdi } from "vuetify/lib/iconsets/mdi"

export const iconify = {
  component: props => h(Icon, props),
}
export const icons = {
  // defaultSet: 'mdi', => Breaks the vertical navigation... wtf
  defaultSet: 'iconify',
  aliases,
  sets: {
    mdi,
    iconify,
  },
}
