import {sessionsCookieName} from "@/stores/auth";

/**
 * Returns a value from a cookie
 *
 * @param cookieName
 * @returns {null|string}
 */
export function getCookieValue(cookieName) {
    const name = cookieName + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');

    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return null;
}

/**
 * Resets a cookie value
 *
 * @param cookieName
 */
export function resetCookieValue(cookieName) {
    // Remove stuff
    document.cookie = cookieName + '=; Max-Age=0';
}

export function setCookieValue(cookieName, cookieValue) {
    document.cookie = `${cookieName}=${cookieValue}; path=/; SameSite=Strict; secure`;
}