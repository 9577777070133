import { z, ZodType } from "zod";

/**
 * The base class of each model definition
 */
export abstract class Model {
    /**
     * The schema used to validate and parse the data to
     */
    public abstract schema: ZodType;

    /**
     * The values that can be accessed through the schema
     */
    declare public values: z.infer<typeof this['schema']>;

    /**
     * Validate, parse and create the given data
     *
     * @param data
     */
    public static validateAndCreate<T extends Model>(this: new () => T, data: any): T {
        const self: T = new this();

        self.values = self.schema.parse(data);

        return self;
    }

    /**
     * Validate, parse and create the given array of data
     *
     * @param data
     */
    public static validateAndCreateMany<T extends Model>(this: new () => T, data: any[]): T[] {
        return data.map(entry => {
            const self: T = new this();

            self.values = self.schema.parse(entry);

            return self;
        });
    }
}