import {useUserStore} from "@/stores/user";
import {datadogRum} from "@datadog/browser-rum";

export function updateDatadogContext() {
    const user = useUserStore()
    // Update the dd rum with user details
    datadogRum.setUser({
        id: user.user.id,
        email: user.user.email,
        name: user.user.first_name + ' ' + user.user.last_name,
    })
}