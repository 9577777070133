import axios from "axios";
import { defineStore } from "pinia";
export const useSettingStore = defineStore("SettingStore", {
    state: () => {
        return {
            userData: {},
        }
    },
    actions: {
        // Fetch user personal information
        fetchUserPersonalInformation() {
            return axios.get(`/api/user`);
        },
        // Fetch Invoices
        fetchInvoices(params) {
            return axios.get(`/api/user/invoices?page=${params?.currentPage}`);
        },
        // Fetch Payment methods
        fetchPyamentDetails(params) {
            return axios.get(`/api/user/payment/method`);
        },
        // Edit User Information
        sendUserPersonalBasicInfo(userInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .put("/api/user/update", userInfo)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        // Add User Address Information
        sendUserAddressInfo(userInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/user/address/add", userInfo)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        // Send User Profile Image
        sendUserProfileImage(profileInfo) {
            return axios.post(`/api/user/avatar`, profileInfo);
        },
        // Card Method Change
        cardSelectSubscription(cardInfo) {
            return axios.post(`/api/user/subscription/select`, cardInfo);
        },
        // Get Current Subscription
        getCurrentSubscription() {
            return axios.get(`/api/user/subscription`);
        },
        // Reset Password
        resetPassword(email) {
            return axios.post(`/api/auth/password/email`, {
                'email' : email
            });
        },
        // Cancel Subscription
        cancelSubscription() {
            return axios.post(`/api/user/subscription/cancel`, {});
        },
        reactivateSubscription() {
            return axios.post('/api/user/subscription/reactivate', {});
        },
        // Fetch Integration services
        fetchIntegrationDetails() {
            return axios.get(`/api/user/integrations/services`);
        },
    },
});
