import {useAuthStore} from "@/stores/auth";
import {useUserStore} from "@/stores/user";
import {useAppStore} from "@/stores/app";
import {useSubscriptionStore} from "@/stores/subscription/subscription-store";

export function canNavigateTo(routerMetaData) {
  const user = useUserStore();
  const appStore = useAppStore();

  if(routerMetaData.redirectIfSingleSubscription && !appStore.useMultipleSubscriptions) {
    return false;
  }

  return user.hasFeature(routerMetaData.feature);
}

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
export function isUserLoggedIn() {
  const auth = useAuthStore()

  console.log("### ROUTER: User is authenticated: " + auth.authenticated)

  return auth.authenticated
}

export function isUserRegisteredSuccessfully() {
  if(!isUserLoggedIn()) return false;

  const userStore = useUserStore();

  return userStore.user.registration_success && userStore.user.last_login_at;
}

export function hasActiveSubscription() {
  const subscription = useSubscriptionStore()

  let hasActiveSubscription = !!subscription.getActiveSubscription() || !subscription.checkForSubscription

  console.log("### ROUTER: User has active subscription: " + hasActiveSubscription)

  return hasActiveSubscription
}
