<script setup lang="ts">

  // Defines
  import {computed} from "vue";

  const props = defineProps({
    icon : {
      required: true,
      type: String,
    },
    color : {
      required: true,
      type: String,
    },
  })

  // Computed
  const backgroundColor = computed(() => {
    // This adds opacity to a hex color, so it is levered from the icon
    // Works only with hex colors
    return `${props.color}14`
  })

</script>

<template>
  <!-- Note from Julian: I tried like 1h to align stuff via flex align, however nothing worked but the spacers did... -->
  <div class="d-flex flex-column">
    <v-spacer></v-spacer>
    <div class="d-inline-block pa-3" style="border-radius: 16px" :style="`background-color: ${backgroundColor}`">
      <v-icon :icon="`mdi:mdi-${icon}`" :color="color" size="large"></v-icon>
      <slot></slot>
    </div>
    <v-spacer></v-spacer>
  </div>
</template>

<style scoped lang="scss">
</style>