/* eslint-disable import/order */
import '@/@fake-db/db'
import '@/@iconify/icons-bundle'
import App from '@/App.vue'
import i18n from '@/plugins/i18n'
import layoutsPlugin from '@/plugins/layouts'
import vuetify from '@/plugins/vuetify'
import { loadFonts } from '@/plugins/webfontloader'
import router from '@/router'
import '@/styles/styles.scss'
import '@core/scss/index.scss'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import Toast from "vue-toastification";
import interceptorsSetup from './interceptors'
import VueTelInput from 'vue-tel-input'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css'
import { useUserStore } from "@/stores/user"
import {useAppStore} from "@/stores/app"
import { datadogRum } from '@datadog/browser-rum'

// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
const options = {
  // You can set your default options here
};

// Import axios for API communication and configure it globally
import axios from 'axios'
import VueAxios from 'vue-axios'
import {useAuthStore} from "@/stores/auth";
import {PiniaDebounce} from "@pinia/plugin-debounce";
import {debounce} from "lodash";

// At very first, start our tracer to submit all frontend errors
// It is ok to put the credentials here, they are for this app only and limited to specific actions
const appVersion = import.meta.env.VITE_APP_VERSION ?? 'local'
console.log("App version: " + appVersion)

// If a app version is set we can start the dd tracer
// App version should be set to all non local builds or can be overriden via .env file
if (import.meta.env.VITE_DATADOG_ENABLED == 'true' || appVersion !== 'local') {
  console.log("Enabling DD RUM")
  datadogRum.init({
    applicationId: '295a054a-e1bb-4029-b169-13de897feb34',
    clientToken: 'pube91bdd8396626f719097e38bf7cd4cfc',
    site: 'datadoghq.eu',
    service: 'tellscale',
    env: import.meta.env.MODE,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: appVersion,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [/https:\/\/api\..*tellscale\.de/]
  });
}

// Configure axios for communication with backend
// Use localhost when in dev mode, integ url when in integ and otherwise use production url
console.log("App running in " + import.meta.env.MODE)
if (import.meta.env.MODE === 'development') {
  // axios.defaults.baseURL = 'https://devoted-marginally-cub.ngrok-free.app'
  axios.defaults.baseURL = 'http://localhost:8000/'
} else if (import.meta.env.MODE === 'staging') {
  axios.defaults.baseURL = 'https://api.staging.tellscale.de/'
} else {
  axios.defaults.baseURL = 'https://api.tellscale.de/'
}


let globalOptions = {
  mode: 'auto',
};

loadFonts()

// Init pinia
const pinia = createPinia()
// Load router into pinia to support redirecting users (esp. on login with incomplete registration)
pinia.use(({ store }) => {
  store.router = markRaw(router)
})
pinia.use(PiniaDebounce(debounce))

const app = createApp(App)

app.use(pinia)

app.use(vuetify)
app.use(layoutsPlugin)
app.use(i18n)
app.use(Toast, options)
app.use(VueAxios, axios) // Register axios globally
app.use(VueTelInput, [globalOptions = {}])

// First init central user store to potentially log the user in AND SET THE CORRECT AUTH HEADERS!
const appStore = useAppStore();
// Workaround to not use await since it is throwing errors in the build
appStore.init().then(() => {
  // init app store before router
  app.use(router)

  // Then create the app
  app.mount('#app')
})
