<script setup>
import { useLayouts } from "@layouts";
import { config } from "@layouts/config";
import { getComputedNavLinkToProp, isNavLinkActive } from "@layouts/utils";
import {useAppStore} from "@/stores/app";
import {useUserStore} from "@/stores/user";
import TsButton from "@/components/app/TsButton.vue";

const app = useAppStore();
const user = useUserStore();

const props = defineProps({
  item: {
    type: null,
    required: true,
  },
});

const router = useRouter();
const { width: windowWidth } = useWindowSize();
const { isVerticalNavMini, dynamicI18nProps } = useLayouts();
const hideTitleAndBadge = isVerticalNavMini(windowWidth);

const featureDialog = ref(false);

const isPremiumFeature = computed(() => {
  return !user.hasFeature(props.item.feature) && app.isFeatureEnabled(props.item.feature);
});

const showLink = computed(() => {
    if(!app.isFeatureEnabled(props.item.feature)) return false;
    if(user.isTeamMember && props.item.feature === 'teams') return false;

    return true;
});

const showPremiumBadge = computed(() => isPremiumFeature.value && showLink.value);

const showFeatureDialog = () => {
  if(!props.item.feature) return;

  if(user.hasFeature(props.item.feature)) return;

  featureDialog.value = true;
}

const redirectToAboSelection = () => {
  router.push('/setting/select-subscription')
  featureDialog.value = false;
}

</script>

<template>
  <v-icon v-if="showPremiumBadge" class='premium-badge' size='small' icon="mdi-crown"></v-icon>

  <v-dialog
      v-model="featureDialog"
      width="800"
      persistent
  >
    <v-card title="Premium Feature" class="pa-3">
      <v-card-text class="mb-7">
        Dieses Feature ist in deinem aktuellen Abonnement nicht enthalten.
        Um vollen Zugriff auf bestimmte Funktionen zu erhalten, kannst du dein Abonnement jederzeit upgraden.
      </v-card-text>

      <v-card-actions class="demo-space-x">
        <TsButton color="primary" @click="redirectToAboSelection()">Jetzt upgraden</TsButton>
        <TsButton color="primary" @click="featureDialog = false">Nicht jetzt</TsButton>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <li class="nav-link" :class="{ 'disabled': item.disable }" v-if="showLink">
    <Component
      :is="!isPremiumFeature ? item.to ? 'RouterLink' : 'a' : 'div'"
      v-bind="getComputedNavLinkToProp(item)"
      @click="showFeatureDialog()"
      :class="{
        'router-link-active router-link-exact-active': isNavLinkActive(item, $router),
        'router-link-premium': isPremiumFeature,
      }"
      v-ripple
    >
      <Component :is="config.app.iconRenderer || 'div'" v-bind="item.icon || config.verticalNav.defaultNavItemIconProps"
        class="nav-item-icon" />
      <TransitionGroup name="transition-slide-x">
        <!-- 👉 Title -->
        <span v-show="!hideTitleAndBadge" key="title"
          class="nav-item-title">
          {{ item.title }}
        </span>

        <!-- 👉 Badge -->
        <span v-if="item.badgeContent" v-show="!hideTitleAndBadge"
          key="badge" class="nav-item-badge" :class="item.badgeClass"
        >
          {{ item.badgeContent }}
        </span>

      </TransitionGroup>
    </Component>
  </li>
</template>

<style lang="scss">
@use "vuetify/lib/styles/tools/_elevation" as mixins_elevation;

.router-link-premium {
  background: linear-gradient(90deg, #fff8eb 0%, #ffe3ab 100%);
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
}

.router-link-premium:hover {
  background: linear-gradient(90deg, #f7f0e1 0%, #f7dca6 100%);
}

.premium-badge {
  position: absolute;
  z-index: 1;
  right: 5%;
  color: white;
  border-radius: 100%;
  margin-top: -0.2em;
  padding: 0.16rem;
  background-color: #fac45a;
  @include mixins_elevation.elevation(3)
}

.layout-vertical-nav {
  display: flex;
  justify-content: space-between;

  .nav-link a {
    display: flex;
    align-items: center;
  }

  .nav-link:hover a {
    display: flex;
    align-items: center;
    background-color: #ffffff !important;
  }
}
</style>
