import axios from "axios";
import {datadogRum} from "@datadog/browser-rum";
import {useUserStore} from "@/stores/user";
import {debounce} from "lodash";

// Need to assign here, because otherwise we can't access it in the intercom js script
const intercomId = ref('')

/**
 * Inits the intercom system with datadog and etc
 *
 * @param store
 * @returns {Promise<void>}
 */
export async function initIntercom(store) {

    // There is an packaage for that, but we get errors with vite so we need to manually build the stuff here
    // Package: https://github.com/mathieustan/vue-intercom/tree/vue3
    // Error: Can't find composeables

    // Load intercom config
    await store.getIntercomConfig()

    // Check if intercom is already booted, we need to first shut it down to restart
    // Otherwise we could not set the properties correctly
    if (store.intercomBooted) {
        console.log("Intercom already booted, shutting it first down")
        window.Intercom('shutdown')
    }

    // Load datadog RUM context and generate url to session replay for intercom
    let ddSessionUrl = "DATADOG NOT ENABLED"
    const ddRum = datadogRum.getInternalContext()
    if (ddRum != null) {
        ddSessionUrl = "https://app.datadoghq.eu/rum/sessions?query=@type:session @session.id:" + ddRum.session_id
    }

    // Set initial intercom config values
    let intercomConfig = {
        app_id: intercomId.value,
        background_color: '#009ee3',
        action_color: '#0077c0',
    }

    // If user is logged in aka the app is active, we should add user details and also disable the button
    const userStore = useUserStore()
    const user = userStore.user
    if (user !== null) {
        // Update url to user id based identifying
        ddSessionUrl = "https://app.datadoghq.eu/rum/sessions?query=@type:session @usr.id:" + user.id
        // Add user details
        intercomConfig = {
            ...intercomConfig,
            email: user.email,
            user_id: user.id,
            name: user.first_name + ' ' + user.last_name,
            created_at: user.created_at,
        }
    }

    // If the user is out of his registration process we should show the intercom app without the floating stuff
    if (user !== null && user.registration_success) {
        console.log("Intercom user is in dashboard")
        // Modify appearence
        intercomConfig = {
            ...intercomConfig,
            hide_default_launcher: true,
            alignment: 'left',
            horizontal_padding: calculateHorizontalPadding()
        }
    }

    // Change position of intercom on resize
    const resizeHandler = debounce(() => {
        intercomConfig.horizontal_padding = calculateHorizontalPadding();
        window.Intercom('boot', intercomConfig);
    }, 250);

    // Event listener for window resize
    if (user) {
        window.addEventListener('resize', resizeHandler);
    }

    // Set dd session url
    intercomConfig = {
        ...intercomConfig,
        'Session Replay' : encodeURI(ddSessionUrl),
    };

    (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + intercomId.value;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
    window.Intercom('boot', intercomConfig);
    window.Intercom('onShow', () => {
        store.intercomVisible = true
    })
    window.Intercom('onHide', () => {
        store.intercomVisible = false
    })
    store.intercomBooted = true
}

// IntercomConfig horizontal_padding calc
function calculateHorizontalPadding() {
    return window.innerWidth < 1265 ? 80 : 270;
}

export async function getIntercomConfig(store) {
    const response = await axios.get('api/intercom')
    store.intercomConfig = response.data
    intercomId.value = store.intercomConfig.id
}

export function toggleIntercom(store) {
    console.log("Toggling intercom, current state is: " + store.intercomVisible)
    // Check if we need to open or close it
    if (!store.intercomVisible) window.Intercom('show')
    else window.Intercom('hide')
}