<script setup>
import navItems from "@/navigation/vertical";
import { useSettingStore } from "@/views/apps/setting/settingStore";

// Composable
import { useSkins } from "@core/composable/useSkins";
import { useThemeConfig } from "@core/composable/useThemeConfig";

// Components
import Footer from "@/layouts/components/Footer.vue";
import UserProfile from "@/layouts/components/UserProfile.vue";

// @layouts plugin
import { VerticalNavLayout } from "@layouts";
import { watchEffect } from "vue";
import Notification from "./Notification.vue";
import TsButton from '@/components/app/TsButton.vue'

const { appRouteTransition, isLessThanOverlayNavBreakpoint } = useThemeConfig();
const { width: windowWidth } = useWindowSize();
const { layoutAttrs, injectSkinClasses } = useSkins();
const currentUserData = ref(JSON.parse(localStorage.getItem("userData")));
const settingStore = useSettingStore();

const getUserData = () => {
  currentUserData.value = JSON.parse(localStorage.getItem("userData"));
};

watchEffect(() => {
  getUserData();
});

const clickAcademyLink = () => window.open('https://tellscale.learningsuite.io/', '_blank');

// ℹ️ This will inject classes in body tag for accurate styling
injectSkinClasses();
</script>

<template>
  <VerticalNavLayout :nav-items="navItems" v-bind="layoutAttrs">
    <!-- 👉 navbar -->
    <template #navbar="{ toggleVerticalOverlayNavActive }">
      <div class="d-flex h-100 align-center">
        <!-- <VBtn v-if="isLessThanOverlayNavBreakpoint(windowWidth)" icon variant="text" color="default" class="ms-n3"
          size="small" @click="toggleVerticalOverlayNavActive(true)">
          <VIcon icon="mdi-menu" size="24" />
        </VBtn>

        <VBtn icon variant="text" color="default" class="ms-lg-n3" size="small">
          <VIcon icon="mdi-magnify" size="24" />
        </VBtn>

        <VSpacer />
        <div class="student-container">
          <div class="student-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path fill="#c6c8cf"
                d="M18 10.5V6l-2.11 1.06A3.999 3.999 0 0 1 12 12a3.999 3.999 0 0 1-3.89-4.94L5 5.5L12 2l7 3.5v5h-1M12 9l-2-1c0 1.1.9 2 2 2s2-.9 2-2l-2 1m2.75-3.58L12.16 4.1L9.47 5.47l2.6 1.32l2.68-1.37M12 13c2.67 0 8 1.33 8 4v3H4v-3c0-2.67 5.33-4 8-4m0 1.9c-3 0-6.1 1.46-6.1 2.1v1.1h12.2V17c0-.64-3.13-2.1-6.1-2.1Z" />
            </svg>
          </div>
          <div class="student-text-container">
            <p class="student-text">Schulungsportal</p>
          </div>
        </div>
        <div class="search-bar">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path fill="#7e7e7e"
              d="m18.9 20.3l-5.6-5.6q-.75.6-1.725.95T9.5 16q-2.725 0-4.612-1.888T3 9.5q0-2.725 1.888-4.612T9.5 3q2.725 0 4.612 1.888T16 9.5q0 1.1-.35 2.075T14.7 13.3l5.625 5.625q.275.275.275.675t-.3.7q-.275.275-.7.275t-.7-.275ZM9.5 14q1.875 0 3.188-1.313T14 9.5q0-1.875-1.313-3.188T9.5 5Q7.625 5 6.312 6.313T5 9.5q0 1.875 1.313 3.188T9.5 14Z" />
          </svg>
          <div class="search-text-container">
            <p class="serach-text">Suche</p>
          </div>
        </div>
        <div class="notification-bar">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path fill="#3751ff"
              d="M21 6.5c0 1.93-1.57 3.5-3.5 3.5S14 8.43 14 6.5S15.57 3 17.5 3S21 4.57 21 6.5m-2 5.29c-.5.13-1 .21-1.5.21A5.51 5.51 0 0 1 12 6.5c0-1.47.58-2.8 1.5-3.79A1.93 1.93 0 0 0 12 2c-1.1 0-2 .9-2 2v.29C7.03 5.17 5 7.9 5 11v6l-2 2v1h18v-1l-2-2v-5.21M12 23c1.11 0 2-.89 2-2h-4a2 2 0 0 0 2 2Z" />
          </svg>
        </div> -->
        <ts-button
            icon="mdi:mdi-account-school"
            class="mr-2"
            type="plain"
            @click="clickAcademyLink"
        >TellScale Academy</ts-button>
        <div style="padding-right: 10px"></div>
        <div class="seprator" />
        <Notification />
        <div class="user-name-container">
          <p class="user-name">
            {{ currentUserData?.first_name }} {{ currentUserData?.last_name }}
          </p>
        </div>
        <!-- <NavBarI18n /> -->
        <!-- <NavbarThemeSwitcher /> -->
        <!-- <NavBarNotifications class="me-2" /> -->
        <UserProfile />
      </div>
    </template>

    <!-- 👉 Pages -->
    <RouterView v-slot="{ Component }">
      <Transition :name="appRouteTransition" mode="out-in">
        <Component :is="Component" />
      </Transition>
    </RouterView>

    <!-- 👉 Footer -->
    <template #footer>
      <Footer />
    </template>

    <!-- 👉 Customizer -->
    <TheCustomizer />
  </VerticalNavLayout>
</template>
<style>
.student-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.student-text {
  margin-left: 10px;
  color: #c6c8cf;
  margin-bottom: 0px;
}

.search-bar {
  margin-left: 3.188rem;
  background-color: white;
  width: 13.5rem;
  height: 2.375rem;
  box-shadow: 0px 10px 60px rgba(226, 236, 249, 0.5);
  border-radius: 12px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding: 10px;
}

.serach-text {
  color: #b5b7c0;
  margin-bottom: 0px;
}

.notification-bar {
  margin-left: 21px;
}

.seprator {
  margin-left: 21px;
  border: 1px solid #dfe0eb;
  height: 32px;
}

.user-name {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  letter-spacing: 0.2px;
  color: #252733;
  margin-bottom: 0px;
}

.user-name-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
}
</style>
