import { setupLayouts } from 'virtual:generated-layouts'
import { createRouter, createWebHistory } from 'vue-router'
import routes from '~pages'
import {
  hasActiveSubscription,
  isUserLoggedIn,
  isUserRegisteredSuccessfully,
  canNavigateTo
} from './utils'
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    // ℹ️ We are redirecting to different pages based on role.
    // NOTE: Role is just for UI purposes. ACL is based on abilities.
    {
      path: '/',
      redirect: to => {
        const userData = JSON.parse(localStorage.getItem('userData') || '{}')
        const userRole = userData && userData.role ? userData.role : null
        if (userRole === 'admin')
          return { name: 'dashboard' }
        if (userRole === 'client')
          return { name: 'access-control' }

        return { name: 'login', query: to.query }
      },
    },
    {
      path: '/cmsRecommendation/cmsEditorMode/:id',
      redirect: to => {
        return {
          name: 'cmsRecommendation-cmsEditorMode', params: { pageData: {}, }, query: { page_id: '' }
        }
      },
      props: {
        // You must set `true` to the default view, which uses ProductDetail
        default: true
      }
    },
    {
      path: '/cmsRecommendation/cmsCreateMode/:id',
      redirect: to => {
        return {
          name: 'cmsRecommendation-cmsCreateMode', params: { pageData: {}, }, query: { page_id: '' }
        }
      },
      props: {
        // You must set `true` to the default view, which uses ProductDetail
        default: true
      }
    },
    ...setupLayouts(routes),
  ],
  scrollBehavior() {
    return { top: 0 }
  },
})
router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  const registerSuccess = isUserRegisteredSuccessfully()
  const activeSubscription = hasActiveSubscription()

  if(!canNavigateTo(to.meta)) {
    // Redirect to login if not logged in
    // ℹ️ Only add `to` query param if `to` route is not index route
    if (!isLoggedIn)
      return next({ name: 'login', query: { to: to.name !== 'index' ? to.fullPath : undefined } })

    // If logged in => not authorized
    return next({ name: 'not-authorized' })
  }

  // quicker redirect to login page if the user is not logged in
  if(!isLoggedIn && !to.meta.redirectIfLoggedIn) {
    return next({ name: 'login', query: { to: to.name !== 'index' ? to.fullPath : undefined } })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    return next({ name: 'dashboard' })
  }

  if (to.meta.redirectIfRegistrationSuccess && registerSuccess) {
    return next({ name: 'dashboard' })
  }

  if (to.meta.redirectIfSubscriptionActive && activeSubscription && registerSuccess) {
    return next({ name: 'dashboard' })
  }

  return next()
})
export default router
