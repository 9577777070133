import {defineStore} from 'pinia'
import {useToast} from "vue-toastification";
import axios from 'axios'
import {updateDatadogContext} from "@/stores/includes/datadog";
import { useAppStore } from "@/stores/app";

const toast = useToast()

export const useUserStore = defineStore('user', {
    // Initial state of store
    state: () => ({ 
        user: null,
        teamExpirePopupConfirmed: false,
        features: null,
        address: null,
        paymentMethod: null,
    }),
    getters: {
        isTeamMember: state => !!state.user?.team_id,
        isSelfPayer(state) {
            return !this.isTeamMember || (this.isTeamMember && state.user?.selfpayer);
        }
    },
    // Available actions
    actions: {
        reset() {
            // Remove from localStorage
            localStorage.removeItem('userData')
        },
        buildFormData(data) {
            const formData = new FormData()
            Object.keys(data).forEach(key => {
                // Only add if not empty
                if (data[key]) formData.append(key, data[key])
                else formData.append(key, '')
            })
            return formData
        },
        async getUserByEmail(email){
            try {
                 return await axios.get(`/api/user/${email}`)
            }
            catch (e) {
            }
        },
        async get() {
            try {
                const response = await axios.get(`/api/user`)
                this.user = response.data.data
                this.features = response.data.features
                this.address = response.data.data.address

                // Set also at local storage
                // This should not be necessary anymore, since it was only done to determine if the user is logged in
                // and we changed that, however to reduce the risk of breaking stuff, we leave that here and remove that
                // later
                localStorage.setItem("userData", JSON.stringify(this.user))

                // Update dd context
                updateDatadogContext()

                console.log("Got user")

            } catch (error) {
                toast.error("Fehler beim laden der Daten für den aktuellen User")
                console.error(error);
            }
        },
        async register(content) {
            try {
                const response = await axios.post(`/api/user/register`, content)
                return true
            } catch (error) {
                const data = error.response?.data
                if (data?.error !== null) return data.error
                toast.error("Leider ist die Registrierung fehlgeschlagen. Wende dich bitte an den Support!")
            }
            return false
        },
        async resendVerificationMail() {
            try {
                const response = await axios.post(`/api/resend-verification-mail`, {
                    email : this.user.email
                })
                this.user = response.data
                toast.success("E-Mail erneut gesendet")
                return true
            } catch (error) {
                // If we have a 400 error, it is, because the user is already verified
                console.log(error)
                if (error.response.status === 400) {
                    return null
                }
                toast.error("Leider ist ein Fehler bei senden der Mail aufgetreten")
                return false
            }
        },
        async updateInitialLastLogin() {
            try {
                await axios.post('api/user/login/initial');
            }
            catch(error) {
                toast.error('Fehler beim initialen einloggen des Users');
            }
        },
        async updateProfilePicture(image) {
            // 2_097_152 bytes == 2 MiB
            if (image.size > 2_097_152) {
                toast.error("Das Bild darf nicht größer als 2MB sein um die Ladezeiten gering zu halten!")
                return
            }

            // Build request
            let formData = new FormData();
            formData.append("image", image);

            try {
                const response = await axios.post(`/api/user/avatar`, formData)
                this.user.avatar_path = response.data
                toast.success("Profilbild aktualisiert")
            } catch (error) {
                toast.error("Fehler beim hochladen des Profilbilds, probiere es am besten nochmal oder verwende ein anderes Bild")
            }
        },
        async updateProfile(content) {
            try {
                const response = await axios.put(`/api/user/${this.user.id}`, content)
                toast.success("Profil aktualisiert")
                this.user = response.data
                return true
            } catch (error) {
                toast.error("Leider ist das aktualisieren des Profils fehlgeschlagen")
                console.error(error)
            }
            return false
        },
        async setAddress(content){
            try {
                const response = await axios.post(`/api/user/address/set`, content)
                this.user = response.data
                toast.success("Adresse gespeichert")
                return true
            } catch (error) {
                toast.error("Leider ist das speichern der Adresse fehlgeschlagen")
            }
            return false
        },
        async setFavicon(favicon) {
            try {
                const response = await axios.post(`/api/user/settings/favicon`, this.buildFormData({
                    favicon: favicon
                }))
                this.user = response.data.data
                toast.success("Favicon gespeichert")
                return true
            } catch (error) {
                toast.error("Leider ist das speichern des Favicon fehlgeschlagen")
            }
            return false
        },
        async setCompanyLogo(logo) {
            try {
                const response = await axios.post(`/api/user/settings/logo`, this.buildFormData({
                    logo: logo
                }))
                this.user = response.data.data
                toast.success("Firmenlogo gespeichert")
                return true
            } catch (error) {
                toast.error("Leider ist das speichern des Firmenlogos fehlgeschlagen")
            }
            return false
        },
        async redirectOnIncompleteRegistration() {
            // If no user is loaded we can skip that too
            if (this.user === null) return false

            // If registered correctly we can just skip that
            if (this.user.registration_success && this.user.last_login_at) return false

            // User not registered correctly, redirect to registration page to finish it up
            let route = 'signup-success'

            // Determine correct route
            switch (this.user.registration_status) {
                case 'REGISTER_ADDRESS': route = 'signup-two'; break;
                case 'REGISTER_PAYMENT': route = 'payment-selection'; break;
            }

            console.log("Redirecting to " + route)

            // Redirect
            await this.router.replace('/' + route)

            return true;
        },
        async getPaymentMethod() {
            try {
                const response = await axios.get(`/api/user/payment/method`)
                this.paymentMethod = response.data
            } catch (error) {
                toast.error("Fehler beim laden der Zahlungsdaten")
                console.error(error);
            }
        },
        async getPricingInfo() {
            try {
                const { data } = await axios.get('/api/user/pricing-info');

                return data;
            }
            catch(error) {
                toast.error("Fehler beim laden der Kostenübersicht")
                console.log(error);
                return null;
            }
        },
        hasFeature(feature) {
            if(feature === null || feature === undefined) return true;

            if(this.features == null) return false;

            const app = useAppStore();

            return app.isFeatureEnabled(feature) && this.features.includes(feature);
        }
    },
  })