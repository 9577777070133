
// Update tokens in state and everywhere else
import axios from "axios";
import {
    useAuthStore,
    refreshBeforeMilliseconds,
    localStorageAccessTokenKey,
    localStorageAuthExpiresKey
} from "@/stores/auth";
import {
    getStringFromStorage,
    setStringToStorage,
} from "@/stores/includes/storage";

/**
 * Update oauth tokens in all relevant places
 *
 * @param accessToken
 * @param expiresAt
 */
export function updateToken(accessToken, expiresAt) {

    const now = new Date()
    const expiresIn = expiresAt - now.getTime()

    // Get state
    const auth = useAuthStore()

    // Cancel old refresh timer
    clearTimeout(auth.refreshFunctionId)

    // If expires in is a positive value (in can be negative, if resetted or not valid)
    if (expiresIn > 0) {
        // Refresh time + 0-10 seconds to avoid race conditions in multiple tabs while refreshing
        const refreshTime = expiresIn - refreshBeforeMilliseconds + (Math.random() * 10000)
        // Add timeout function for refreshing
        auth.refreshFunctionId = setTimeout(auth.refresh, refreshTime)
    }

    // We need to set these here ourself, since the update storage events are only called in different browser tabs
    auth.accessToken = accessToken
    auth.expiresAt = expiresAt

    // We need to set the accessToken FIRST because we listen on the expiresAt and therefore would call too EARLY
    setStringToStorage(localStorageAccessTokenKey, accessToken)
    setStringToStorage(localStorageAuthExpiresKey, expiresAt)

    // Add to axios requests
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
}

/**
 * Load tokens from local storage
 *
 * @returns {boolean}
 */
export function loadTokenFromStorage() {

    const accessToken = getStringFromStorage(localStorageAccessTokenKey)
    const expiresAt = getStringFromStorage(localStorageAuthExpiresKey)

    if (accessToken === null || expiresAt === null) return false

    updateToken(accessToken, expiresAt)

    return true
}