<script>
import NotificationActivity from "@/views/dashboards/crm/NotificationActivity.vue";
// import "./ComponentStyle.scss";
export default {
  setup() {
    const router = useRouter();
    const userData = JSON.parse(localStorage.getItem("userData") || "null");
    const avatarBadgeProps = {
      dot: true,
      location: "top right",
      offsetX: 3,
      offsetY: 3,
      bordered: true,
    };
    return {
      router,
      userData,
      avatarBadgeProps,
    };
  },
  methods: {
    logout: function () {
      // Remove "userData" from localStorage
      localStorage.removeItem("userData");
      this.router.push("/login").then(() => {
        // Logout from backend
        this.axios.post("/logout");
      });
    },
    showFAQs() {
      window.open("https://www.tellscale.de/", "_blank").focus();
    },
  },
  components: { NotificationActivity },
};
</script>

<template>
  <VAvatar class="cursor-pointer" color="primary" variant="tonal">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="#00b8fe"
        d="M19 17v-5.2c-.5.1-1 .2-1.5.2H17v6H7v-7c0-2.8 2.2-5 5-5c.1-1.3.7-2.4 1.5-3.3c-.3-.4-.9-.7-1.5-.7c-1.1 0-2 .9-2 2v.3C7 5.2 5 7.9 5 11v6l-2 2v1h18v-1l-2-2m-9 4c0 1.1.9 2 2 2s2-.9 2-2h-4M21 6.5c0 1.9-1.6 3.5-3.5 3.5S14 8.4 14 6.5S15.6 3 17.5 3S21 4.6 21 6.5"
      />
    </svg>

    <!-- SECTION Menu -->
    <VMenu activator="parent" width="330" location="bottom end" offset="14px">
      <NotificationActivity />
    </VMenu>
    <!-- !SECTION -->
  </VAvatar>
</template>
